import { Fragment, useState } from "react";
import API from '../../../config/API';
import {useNavigate} from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';

const Incabin = () => {
  const [file, setFile] = useState(null);
  const [image, setImage] = useState(null);
  const [alert, setAlert] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [response, setResponse] = useState("");
  const [waitingSamples, setWaitingSamples] = useState(false);
  const [generationResponse, setGenerationResponse] = useState("");
  const [state, setState] = useState({prompt:"", disabled:false});
  const [iterations, setIterations] = useState(1);
  const nav = useNavigate();

  const handleFileChange = (event) => {
    setImage(URL.createObjectURL(event.target.files[0]));
    setFile(event.target.files[0]);
  };

  const handleIterationsChange = (event) => {
    setIterations(event.target.value);
  };

  const handleResponseChange = event => {
    setResponse(event.target.value);
  }

  const requestResponse = () =>{
    setWaiting(true);
    setState({...state, disabled:true});

    const formData = new FormData();
    formData.append('file', file);

    API.postForm('/v3/api/incabin/query', formData).then( (e)=>{
      setResponse(JSON.stringify(e))
      setState({...state, disabled:false});
      setWaiting(false);
  } )
  };

  const generateSamples = () => {
    setWaitingSamples(true);
    API.post('/v3/api/incabin/generateSamples', {
      data:{
        dataset_name: "incabin",
        batch_name: "generated",
        workspace_name: "incabingemini",
        launcher_version: "dev-",
        occupant_distribution: response,
        iterations: iterations
      }
    }).then( (e)=>{
      setGenerationResponse("Generated");
      setWaitingSamples(false);
    } )
  };

  const isValidInput = () => {
    return !state.disabled && file;
  }

  return(
    <Fragment>
      <h2>Image descriptor for In-cabin</h2>
      <Container>
        <Row>
          {state?.alert && <div className={"alert alert-"+state.alert+" mt-5"}>{state.message}</div>}
        </Row>
        <Row>
          <Col>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Incabin Image</Form.Label>
              <Form.Control type="file" onChange={handleFileChange} />
              <Image src={image} thumbnail fluid/>
            </Form.Group>
            { !waiting && <button className="btn btn-success " onClick={requestResponse} disabled={!isValidInput()} >Send</button> }
            { waiting && <Spinner animation="border" variant="success" /> }
            <Toast show={!!alert} onClose={ () => { setAlert("") }}>
              <Toast.Body>{alert}</Toast.Body>
            </Toast>
          </Col>
          <Col>

            <Form.Group controlId="formResponse" className="mb-3">
              <Form.Label>Response</Form.Label>
              <Form.Control as="textarea" rows="10" type="text" value={response} onChange={handleResponseChange} />
            </Form.Group>
            {
              response &&
              <Form.Group as={Row} className="mb-3" controlId="formPlaintextEmail">
                <Form.Label column sm="2">
                  Iterations
                </Form.Label>
                <Col sm="10">
                  <Form.Control type="number" defaultValue="1" onChange={handleIterationsChange}/>
                </Col>
              </Form.Group>
            }
            { (response && !waitingSamples) && <button className="btn btn-success" onClick={generateSamples} >Generate Similar Samples</button> }
            { (response && waitingSamples) && <Spinner animation="border" variant="success" /> }
            <Toast show={!!generationResponse} delay={3000} autohide onClose={ () => { setGenerationResponse("") }}>
              <Toast.Body>{generationResponse}</Toast.Body>
            </Toast>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
};

export default Incabin;